// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import { Store } from 'Store'
import { updateLead } from 'Actions'
import Sidebar from 'components/Sidebar'
import MediaQuery from 'react-responsive'
import Footer from 'components/Footer'
import TopArea from 'components/TopArea'
import Header from 'components/Header'

import LeadGenForm from 'components/LeadGenForm/Step2'

import styles from './styles.module.scss'

type Props = {
    location: Location
}

export default (props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/step-2', '')}${props.location.search}`} push />
    }

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('step-2', 'step-3')}${props.location.search}`} push />
    }

    return (
        <>
            <div className={styles.layoutWrapper}>
                <MediaQuery maxWidth={767}>
                    <TopArea />
                </MediaQuery>

                <MediaQuery maxWidth={979}>
                    <Header date={state.campaign.expiryDate} step={2} />
                </MediaQuery>

                <div className={styles.layout}>
                    <div className={styles.sidebar}>
                        <Sidebar />
                    </div>

                    <div className={styles.step}>
                        <MediaQuery minWidth={980}>
                            <Header date={state.campaign.expiryDate} step={2} />
                        </MediaQuery>

                        <div className={styles.container}>
                            <div className={styles.formContainer}>
                                <LeadGenForm
                                    onSubmit={async (data, done) => {
                                        try {
                                            let lead = {
                                                fields: data,
                                                isComplete: false
                                            }
                                            await updateLead(dispatch, state.code, lead)
                                            setSubmitted(true)
                                            window.scrollTo(0, 0)
                                        } catch (err) {
                                            alert('Oops something went wrong')
                                            console.error(err)
                                            done(err)
                                        }
                                    }}
                                    initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MediaQuery maxWidth={767}>
                <Footer />
            </MediaQuery>
        </>
    )
}
