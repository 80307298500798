// @flow
import * as React from 'react'
import { withRouter } from 'react-router-dom'

import { Store } from 'Store'
import LandingMask from 'components/LandingMask'
import Hero from 'components/Hero'

import TopBar from 'components/TopBar'
import ValuesSummary from 'components/ValuesSummary'
import Footer from 'components/Footer'

import styles from './styles.module.scss'

export default withRouter(props => {
    const { dispatch } = React.useContext(Store)

    React.useMemo(() => props.history.listen(() => {
        if (window.location.pathname.split('/').length === 2) {
            dispatch({
                type: 'SET_LEAD',
                payload: null
            })
        }
    }), [props.history, dispatch])

    const { state } = React.useContext(Store)

    return (
        <>
            <TopBar
                locale={state.locale}
                date={state.campaign.expiryDate}
            />

            <LandingMask className={styles.container}>
                <Hero />
            </LandingMask>

            <div className={styles.valuesSummary}>
                <ValuesSummary />
            </div>

            <Footer />
        </>
    )
})
