// @flow
import * as React from 'react'
import cn from 'classnames'

import { withTranslation, Trans } from 'react-i18next'
import { Store } from 'Store'
import { Currency } from 'i18n/NumberFormatter'
import { formatDateTime } from 'i18n/DateFormat'

import PromoVoucher from 'components/PromoVoucher'
import usePromoCode from 'util/hooks/usePromoCode'

import styles from './styles.module.scss'
import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'
import { wrapText } from '../Form/Helpers'

function PromoService (props) {
    const promoCode = usePromoCode()
    const { state } = React.useContext(Store)
    const { campaign } = state
    const { t, theme } = props
    const currencyFormatter = Currency(state.currency, state.locale)

    const terms = wrapText(
        campaign.incentives.voucher.terms.replace('[dealership_name]', campaign.client.name),
        180
    )

    return (
        <div className={styles.container}>
            <div className={styles.topContainer} style={{
                backgroundImage: `url(${theme.bg})`
            }}>
                <div className={styles.bubbleText} style={{
                    backgroundColor: theme.colors.primary
                }}>
                    <div className={styles.bubbleTitle}>
                        <Trans i18Key="bubble.title">
                            <strong>HURRY!</strong> THIS EXCLUSIVE OFFERING ENDS
                        </Trans>
                        {t('')}&nbsp;</div>
                    <div className={styles.bubbleDescription} style={{
                        color: theme.colors.secondary
                    }}>
                        {t('bubble.description', {
                            expiryDate: formatDateTime(state.campaign.expiryDate, 'long')
                        })}
                    </div>
                </div>

                <PromoVoucher width="100%" promoCode={promoCode} />
            </div>

            <div className={styles.bottomContainer}>
                <h1 className={styles.title} style={{ color: theme.titleColor }}>
                    <Trans i18Key="title">
                        <strong>ON NOW!</strong> FOR SELECT CLIENTS
                    </Trans>
                </h1>

                <p className={styles.description}>
                    {t('description', {
                        expiry: formatDateTime(state.campaign.expiryDate, 'long'),
                        voucherName: state.campaign.incentives.voucher.name,
                        voucherValue: currencyFormatter.format(state.campaign.incentives.voucher.value),
                        voucherValueRaw: state.campaign.incentives.voucher.value,
                        voucherType: state.campaign.incentives.voucher.type
                    })}
                </p>

                {theme.alternativeLogoLayout &&
                    <div className={styles.alternativeLogoLayout}>
                        <img src={theme.manufacturerLogo} alt=""/>
                    </div>
                }

                <div className={styles.bottomRibbon} />

                <div className={styles.footerContainer}>
                    <div className={styles.footerDisclaimer} style={{ color: theme.colors.ternary }}>
                        {terms}
                    </div>
                    {!theme.alternativeLogoLayout &&
                        <div className={cn(styles.footerLogo, { [styles.bigLogo]: theme.bigLogo })}>
                            <img src={theme.manufacturerLogo} alt=""/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(themes)(PromoService))
