// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import { Store } from 'Store'
import { updateLead } from 'Actions'
import Sidebar from 'components/Sidebar'
import MediaQuery from 'react-responsive'
import Footer from 'components/Footer'
import TopArea from 'components/TopArea'
import Header from 'components/Header'
import LeadGenForm from 'components/LeadGenForm/Step3'

import styles from './styles.module.scss'

export default props => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    const handleSelect = React.useCallback(async (make, model, year, color) => {
        const lead = {
            ...state.lead,
            fields: {
                ...state.lead.fields,
                newVehicleMake: make,
                newVehicleModel: model,
                newVehicleModelYear: year,
                newVehicleModelColor: color
            }
        }
        await updateLead(dispatch, state.code, lead)
        setSubmitted(true)
    }, [state.lead, state.code, dispatch])

    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/step-3', '')}${props.location.search}`} push />
    }

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('step-3', 'step-4')}${props.location.search}`} push />
    }

    return (
        <>
            <div className={styles.layoutWrapper}>
                <MediaQuery maxWidth={767}>
                    <TopArea />
                </MediaQuery>

                <MediaQuery maxWidth={979}>
                    <Header date={state.campaign.expiryDate} step={3} />
                </MediaQuery>

                <div className={styles.layout}>
                    <div className={styles.sidebar}>
                        <Sidebar />
                    </div>

                    <div className={styles.step}>
                        <MediaQuery minWidth={980}>
                            <Header date={state.campaign.expiryDate} step={3} />
                        </MediaQuery>

                        <div className={styles.container}>
                            <div className={styles.formContainer}>
                                <LeadGenForm
                                    onSelect={handleSelect}
                                    setSubmitted={setSubmitted}
                                    initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MediaQuery maxWidth={767}>
                <Footer />
            </MediaQuery>
        </>
    )
}
