// @flow
import * as React from 'react'
import { Store } from 'Store.js'
import cn from 'classnames'
import withTheme from 'hoc/withTheme'

// import MediaQuery from 'react-responsive'
import VoucherValue from 'components/VoucherValue'
import CashbackAndRates from 'components/CashbackAndRates'
import BonusBox from 'components/BonusBox'

import styles from './styles.module.scss'

import supportedThemes from './themes/__supportedThemes'

const ValuesSummary = ({ theme }) => {
    const { state } = React.useContext(Store)
    const incentives = state.campaign.incentives.manufacturer
    const bonusBoxMod = theme.bonusBox
    const hiddenCashBack = state.campaign.incentives.manufacturer?.cashBack === 0

    return (
        <div className={cn(styles.container, {
            [styles.areCombinable]: incentives?.areCombinable,
            [styles.bonusBox]: bonusBoxMod,
            [styles.hiddenCashBack]: hiddenCashBack,
            [styles.noIncentives]: !incentives
        })}>
            <div className={styles.summaryFooter}>
                <div className={styles.voucherContainer}>
                    <VoucherValue hiddeImage={bonusBoxMod} />
                </div>

                {incentives &&
                    <>
                        <div />
                        <div className={styles.cashbackAndRatesContainer}>
                            <CashbackAndRates
                                className={styles.cashbackAndRates}
                            />
                        </div>
                    </>
                }

                {bonusBoxMod && (
                    <>
                        <div />
                        <BonusBox className={styles.bonus} />
                    </>
                )}
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(ValuesSummary)
