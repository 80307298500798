// @flow
import * as React from 'react'

import Countdown from 'components/Countdown'
import AbsoluteTime from 'components/AbsoluteTime'
import cn from 'classnames'
import Arrow from './Arrow'
import Checkmark from './Checkmark'

import withTheme from 'hoc/withTheme'
import { withTranslation } from 'react-i18next'

import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

const steps = [
    {
        step: 1,
        text: 'Contact details'
    },
    {
        step: 2,
        text: 'Current vehicle'
    },
    {
        step: 3,
        text: 'New vehicle selection'
    },
    {
        step: 4,
        text: 'Claim voucher'
    }
]

function Header ({ theme, t, step = 1, date }) {
    return (
        <>
            <div className={styles.bgWrapper} style={{
                backgroundColor: theme.primaryColor
            }}>
                <div
                    className={styles.expiryText}
                    style={{
                        color: theme.color
                    }}
                >
                    {theme.logo && <div className={styles.logo} style={{
                        backgroundImage: `url(${theme.logo})`
                    }} />}

                    {date && (
                        <span className={styles.headerText}>
                            {t('event_expiry')}
                            &nbsp;
                            <strong>
                                <AbsoluteTime
                                    dateFormat='long'
                                    date={date}
                                />
                            </strong>
                        </span>
                    )}
                </div>

                {date && (
                    <div className={styles.countdownContainer}>
                        <Countdown
                            dateFormat='long'
                            date={date}
                        />
                    </div>
                )}

            </div>

            <div className={styles.breadcrumb}>
                <div className={styles.breadcrumbWrapper}>
                    {
                    steps.map(({ text, step: stepIndex }) => (
                        <div className={cn(styles.breadcrumbItem, {
                            [styles.active]: step === stepIndex
                        })} key={stepIndex}>
                            <div className={styles.bubble} style={{
                                '--primary-color': theme.ternaryColor,
                                '--secondary-color': theme.ternaryColor
                            }}>
                                {stepIndex < step
                                    ? (<Checkmark />)
                                    : stepIndex
                                }
                            </div>
                            <span className={styles.text}>
                                {text}
                            </span>
                            {stepIndex < steps.length && (
                                <span className={styles.arrow}>
                                    <Arrow
                                        color={step === stepIndex ? theme.ternaryColor : '#E5E5E5'}
                                    />
                                </span>
                            )}
                        </div>
                    ))
                }
                </div>
            </div>

        </>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Header))
