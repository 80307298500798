// @flow
import * as React from 'react'
import cn from 'classnames'

import { withTranslation } from 'react-i18next'

import SidebarFooter from 'components/SidebarFooter'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'
import styles from './styles.module.scss'

import transDomain from './translations/index.translations'

function ShoppingSidebar (props) {
    const { theme, className, children } = props
    return (
        <div className={cn(styles.container, className)} style={{
            backgroundColor: theme.bgColor,
            backgroundImage: `url(${theme.bgImage})`
        }}>
            <div className={styles.body}>
                {children}
            </div>
            <div className={styles.footerContainer}>
                <SidebarFooter />
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(ShoppingSidebar))
