// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

type Props = WithFormProps | {
    values: {
        firstName: ?string,
        lastName: ?string,
        email: ?string,
        mobilePhone: ?string,
    }
}

function Step1 (props: Props) {
    const { theme } = props
    const { t } = useTranslation(transDomain)
    return (
        <>
            <h2 className={styles.heading} style={{
                color: theme.primaryColor
            }}>
                {t('heading')}
            </h2>
            <div className={styles.description}>
                {t('description')}
            </div>
            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.firstName || null}
                            defaultValue={props.values.firstName || null}
                            onChange={value => props.handleFieldChange('firstName', value)}
                            type='text'
                            label={t('label.firstName')}
                            placeholder={t('placeholder.firstName')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.lastName || null}
                            defaultValue={props.values.lastName || null}
                            onChange={value => props.handleFieldChange('lastName', value)}
                            type='text'
                            label={t('label.lastName')}
                            placeholder={t('placeholder.lastName')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>
            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.email || null}
                            defaultValue={props.values.email || null}
                            onChange={value =>
                                props.handleFieldChange('email', value)
                            }
                            type='email'
                            label={t('label.emailAddress')}
                            placeholder={t('placeholder.emailAddress')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.eveningPhone || null}
                            defaultValue={props.values.mobilePhone || null}
                            onChange={value => props.handleFieldChange('mobilePhone', value)}
                            type='phone'
                            label={t('label.mobilePhone')}
                            placeholder={t('placeholder.mobilePhone')}
                            required={true}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.Submit
                    isLoading={props.isLoading}
                    label={t('label.continueToStep2')}
                    style={{
                        background: theme.background,
                        color: theme.color,
                        marginTop: '3em'
                    }}
                />
            </Form.Row>
        </>
    )
}

export default withForm()(withTheme(supportedThemes)(Step1))
