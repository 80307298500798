import * as React from 'react'
import cn from 'classnames'
import withTheme from 'hoc/withTheme'
import { withTranslation } from 'react-i18next'

import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

export const BonusBox = ({ theme, t, className }) => {
    return (
        <div
            className={cn(styles.bonusBox, className)}
            style={{
                '--primary-color': theme.primaryColor
            }}>
            <div>PLUS receive a</div>
            <strong>$500</strong>
            <div>Black Friday Bonus</div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(BonusBox))
