// @flow
import * as React from 'react'
import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import RegistrationForm from 'components/RegistrationForm'
import Timer from 'components/Timer'
import styles from './styles.module.scss'
import cn from 'classnames'

import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'
import { Currency } from 'i18n/NumberFormatter'

import { Store } from 'Store.js'
import MainLogo from 'components/MainLogo'

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

function Hero (props) {
    const { theme, t } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const voucher = state.campaign.incentives.voucher

    return (
        <div className={styles.container}>
            <span className={cn(styles.mask, styles.mask0)} style={{
                backgroundImage: `url(${theme.mask0})`
            }} />

            <MainLogo className={styles.mainLogo} />

            <div className={styles.carsWrapper}>
                <div className={styles.cars} style={{
                    backgroundImage: isIE11 && `url(${theme.cars})`,
                    '--cars': `url(${theme.cars})`,
                    '--cars-tablet': `url(${theme.carsTablet})`,
                    '--cars-mobile': `url(${theme.carsMobile})`
                }} />
            </div>

            <span
                className={cn(styles.mask, styles.mask1)}
                style={{
                    backgroundImage: `url(${theme.mask1})`
                }}
            />

            <Timer date={state.campaign.expiryDate} />

            <div className={styles.registerForm}>
                <div className={styles.formContainer}>
                    <span
                        className={styles.voucherMessage}
                        style={{ color: theme.primaryColor }}
                        dangerouslySetInnerHTML={{
                            __html: t('title', {
                                voucherName: voucher.name,
                                type: voucher.type,
                                value: currencyFormatter.format(voucher.value)
                            })
                        }}
                        >
                    </span>

                    <RegistrationForm />
                </div>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Hero))
