
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsf274dd3cb3ed01e87ab2014c8f0bd9bfen_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'f274dd3cb3ed01e87ab2014c8f0bd9bf', flattenObject(nsf274dd3cb3ed01e87ab2014c8f0bd9bfen_US), true, true);
        
            import nsf274dd3cb3ed01e87ab2014c8f0bd9bfen from './en.yaml'
            i18n.addResourceBundle('en', 'f274dd3cb3ed01e87ab2014c8f0bd9bf', flattenObject(nsf274dd3cb3ed01e87ab2014c8f0bd9bfen), true, true);
        
        export default 'f274dd3cb3ed01e87ab2014c8f0bd9bf';
    