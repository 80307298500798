
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns3ec7b4b6951b11e6efab8626596c25d8en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '3ec7b4b6951b11e6efab8626596c25d8', flattenObject(ns3ec7b4b6951b11e6efab8626596c25d8en_US), true, true);
        
            import ns3ec7b4b6951b11e6efab8626596c25d8en from './en.yaml'
            i18n.addResourceBundle('en', '3ec7b4b6951b11e6efab8626596c25d8', flattenObject(ns3ec7b4b6951b11e6efab8626596c25d8en), true, true);
        
        export default '3ec7b4b6951b11e6efab8626596c25d8';
    