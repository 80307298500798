// @flow
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import styles from './styles.module.scss'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'
import AbsoluteTime from 'components/AbsoluteTime'

import cn from 'classnames'

type Props = {
    date: Date
};

class TopBar extends Component<Props, { formattedDate: { [string]: string } }> {
    static defaultProps = {
        date: new Date()
    };

    state = {
        fixedOnTop: false
    }

    handleScroll = (e) => {
        if (window.pageYOffset > 32 && !this.statefixedOnTop) {
            this.setState({
                fixedOnTop: true,
                pageYOffset: window.pageYOffset
            })
        } else if (this.state.fixedOnTop) {
            this.setState({
                fixedOnTop: false,
                pageYOffset: 0
            })
        }
    }

    shouldComponentUpdate (nextProps, nextState) {
        return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)
    }

    componentDidMount () {
        this.refEvent = window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount () {
        window.removeEventListener('scroll', this.refEvent)
    }

    render () {
        const { t, theme } = this.props
        return (
            <>
                <div className={styles.expiryTextTopArea}>
                    <div className={styles.expiryText} style={{ color: theme.color }}>
                        <span>{t('event_expiry')}&nbsp;<strong><AbsoluteTime dateFormat='long' date={this.props.date} /></strong></span>
                    </div>
                </div>

                <div className={cn(styles.container, {
                    [styles.fixedOnTop]: this.state.fixedOnTop
                })}>
                    {theme.showLogo &&
                        <div className={styles.logo}>
                            <img src={theme.logo} alt="" style={{ ...theme.logoImgStyles || {} }} />
                        </div>
                    }

                    <div className={styles.expiryText} style={{ color: theme.color }}>
                        <span>{t('event_expiry')}&nbsp;<strong><AbsoluteTime dateFormat='long' date={this.props.date} /></strong></span>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(TopBar))
