// @flow
import * as React from 'react'
import { Store } from 'Store'

import SuccessInfoArea from 'components/SuccessInfoArea'
import MediaQuery from 'react-responsive'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import TopArea from 'components/TopArea'
import HeaderWithVoucher from 'components/HeaderWithVoucher'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Sidebar from 'components/Sidebar'

import styles from './styles.module.scss'

function SuccessInfo () {
    const { state } = React.useContext(Store)

    return (
        <>
            <div className={styles.layoutWrapper}>
                <MediaQuery maxWidth={767}>
                    <TopArea />
                </MediaQuery>

                <MediaQuery maxWidth={1023}>
                    <Header date={state.campaign.expiryDate} step={4} />
                </MediaQuery>

                <div className={styles.layout}>

                    <div className={styles.sidebar}>
                        <Sidebar />
                    </div>

                    <div className={styles.step}>
                        <MediaQuery minWidth={1024}>
                            <Header date={state.campaign.expiryDate} step={4} />
                        </MediaQuery>

                        <div className={styles.container}>
                            <div className={styles.formContainer}>
                                <HeaderWithVoucher />
                                <SuccessInfoArea />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MediaQuery maxWidth={767}>
                <Footer />
            </MediaQuery>
        </>
    )
}

export default withTheme(supportedThemes)(SuccessInfo)
