import React from 'react'

function Arrow ({
    color
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="19"
            viewBox="0 0 8 19"
    >
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
      >
                <g stroke={color} transform="translate(-741 -104)">
                    <g transform="translate(522 94)">
                        <path d="M220 28.25L225.911133 19.5 220 10.75"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

Arrow.defaultProps = {
    color: '#123574'
}

export default Arrow
