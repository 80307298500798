// @flow
import * as React from 'react'

import { Store } from 'Store.js'
import { withTranslation } from 'react-i18next'
import { Currency } from 'i18n/NumberFormatter'
import withTheme from 'hoc/withTheme'
import cn from 'classnames'

import transDomain from './translations/index.translations'
import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

function VoucherValue (props) {
    const { t, theme, hiddeImage } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const voucher = state.campaign.incentives.voucher
    return (
        <div className={cn(styles.container, {
            [styles.hiddeImage]: !theme.backgroundImage
        })} style={{
            color: theme.color,
            backgroundColor: theme.background
        }}>
            <div className={styles.leftSide}>
                <span
                    className={styles.title}
                    style={{ color: theme.titleColor }}>
                    {t('title', {
                        voucher: state.campaign.incentives.voucher.name,
                        type: state.campaign.incentives.voucher.type
                    })}
                </span>

                <span
                    className={styles.value}
                    style={{ color: theme.valueColor }}>
                    {currencyFormatter.format(voucher.value)}
                </span>
            </div>

            {(!hiddeImage && theme.backgroundImage) && (
                <div className={styles.imgWrapper}>
                    <div className={styles.img}
                        style={{
                            backgroundImage: `url(${theme.backgroundImage})`
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(VoucherValue))
