import * as React from 'react'

import withTheme from 'hoc/withTheme'
import cn from 'classnames'

import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

const LandingMask = ({
    theme,
    children,
    className
}) => {
    return (

        <div className={cn(styles.container, className)} style={{
            backgroundImage: isIE11 && `url(${theme.backgroundImage})`,
            '--background-image': `url(${theme.backgroundImage})`,
            '--background-tablet-image': `url(${theme.backgroundTabletImage})`,
            '--background-mobile-image': `url(${theme.backgroundMobileImage})`
        }}>
            {children}
        </div>
    )
}

export default withTheme(supportedThemes)(LandingMask)
