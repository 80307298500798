
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns03e7712a59387caea8f97ffccfa16d18en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '03e7712a59387caea8f97ffccfa16d18', flattenObject(ns03e7712a59387caea8f97ffccfa16d18en_US), true, true);
        
            import ns03e7712a59387caea8f97ffccfa16d18en from './en.yaml'
            i18n.addResourceBundle('en', '03e7712a59387caea8f97ffccfa16d18', flattenObject(ns03e7712a59387caea8f97ffccfa16d18en), true, true);
        
        export default '03e7712a59387caea8f97ffccfa16d18';
    