import React from 'react'

function Checkmark () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="9"
            viewBox="0 0 9 9"
    >
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
      >
                <g stroke="#FFF" strokeWidth="1.5" transform="translate(-600 -110)">
                    <g transform="translate(522 94)">
                        <g transform="translate(73 11)">
                            <path d="M6 10.2431641L8.66259766 12.9057617 12.9916992 6"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Checkmark
