// @flow
import * as React from 'react'
import cn from 'classnames'

import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import { Currency, Percent } from 'i18n/NumberFormatter'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

function CashbackAndRates (props) {
    const { theme, small } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const incentives = state.campaign.incentives.manufacturer
    const titleColor = !small ? theme.titleColor : theme.smallTitleColor
    const valueColor = !small ? theme.valueColor : theme.smallValueColor
    const hiddenCashBack = state.campaign.incentives.manufacturer.cashBack === 0
    const hiddenInterestRate = props.theme.hiddenInterestRate

    return (
        <div className={cn(styles.container, props.className, {
            [styles.areCombinable]: incentives.areCombinable,
            [styles.hiddenCashBack]: hiddenCashBack,
            [styles.hiddenInterestRate]: hiddenInterestRate
        })}>
            <div className={styles.cashBack} style={{
                backgroundColor: theme.cashBackBg
            }}>
                <span className={styles.cashBackTitle} style={{ color: titleColor }}>
                    <Trans i18nKey="cash_back">
                        Combine with  <br /><strong>Cash Incentives</strong> up to
                    </Trans>
                </span>
                <span className={cn(styles.cashBackValue, { [styles.smallValues]: props.small })} style={{ color: valueColor }}>{currencyFormatter.format(incentives.cashBack)}</span>
            </div>
            <div className={styles.space}></div>
            <div className={styles.rates} style={{
                backgroundColor: theme.ratesBg
            }}>
                <span className={styles.ratesTitle} style={{ color: titleColor }}>
                    <Trans i18nKey="rates">
                        Don't forget about<br />our <strong>Rates  as low as</strong>
                    </Trans>
                </span>
                <span className={cn(styles.ratesValue, { [styles.smallValues]: props.small })} style={{ color: valueColor }}>{Percent(state.locale).format((Number.parseFloat(incentives.interestRate).toPrecision(3) / 100) || 0)}</span>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(CashbackAndRates))
